import { createContext } from 'react';
import { provider } from 'web3-core';
import { WalletConnectorType } from 'utils/connectors';

export type WalletStatus = 'disconnected' | 'connected' | 'loading';

export interface WalletContextValues {
  account: string | null | undefined;
  ethereum: provider | null;
  status: WalletStatus;
  connect: (walletConnectorType: WalletConnectorType) => void;
  connector: WalletConnectorType | null;
  reset: () => void;
}

export const WalletContext = createContext<WalletContextValues>({
  account: null,
  ethereum: null,
  status: 'disconnected',
  connect: () => {},
  connector: null,
  reset: () => {},
});
