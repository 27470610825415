import React from 'react';
import { Container, Card, CardContent } from 'react-neu';
import { NavLink } from 'react-router-dom';
import { Page } from 'components/page';

export const Home: React.FC = () => {
  return (
    <Page>
      <Container size="lg">
        <Card>
          <CardContent>
            <p>Crypto tools:</p>
            <ul>
              <li>
                <NavLink exact to="/bancor">
                  Bancor tools
                </NavLink>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};
