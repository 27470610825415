import React from 'react';
import { Container, Card, CardContent } from 'react-neu';
import { Page } from 'components/page';

export const Bancor: React.FC = () => {
  return (
    <Page>
      <Container size="lg">
        <Card>
          <CardContent>
            <p>todo</p>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};
