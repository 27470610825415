import React, { useEffect } from 'react';
import { Emoji, Switch, SwitchButton, useTheme } from 'react-neu';
import { useLocalStorage } from 'hooks/use-local-storage';
import { DARK_MODE_STORAGE_KEY } from 'constants/storage';

export const DarkModeSwitch: React.FC = () => {
  const { darkMode, onToggleDarkMode } = useTheme();
  const [_darkModeSetting, setDarkModeSetting] = useLocalStorage(DARK_MODE_STORAGE_KEY, darkMode);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.style.setProperty(
        '--gridline-drop-shadow',
        'drop-shadow( 0px 1px 0px rgba(240, 240, 240, 0.13))',
      );
    } else {
      document.documentElement.style.setProperty(
        '--gridline-drop-shadow',
        'drop-shadow(0 -1px 0px hsl(338deg 20% 90% / 100%))',
      );
    }
    setDarkModeSetting(darkMode);
  }, [darkMode, setDarkModeSetting]);

  return (
    <Switch>
      <SwitchButton active={!darkMode} onClick={onToggleDarkMode} round>
        <Emoji emoji="🌞" />
      </SwitchButton>
      <SwitchButton active={darkMode} onClick={onToggleDarkMode} round>
        <Emoji emoji="🌚" />
      </SwitchButton>
    </Switch>
  );
};
