import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-neu';
import { useWallet } from 'hooks/use-wallet';
import { ACCOUNT_ADDRESS_STORAGE_KEY, WALLET_PROVIDER_STORAGE_KEY } from 'constants/storage';

export const WalletButton: React.FC = () => {
  const [userAccount, setUserAccount] = useState<string | null>();
  const { connect, reset, account } = useWallet();

  const handleSignOut = useCallback(() => {
    localStorage.removeItem(ACCOUNT_ADDRESS_STORAGE_KEY);
    localStorage.removeItem(WALLET_PROVIDER_STORAGE_KEY);
    reset();
    setUserAccount(null);
  }, [reset]);

  const handleConnectMetamask = useCallback(() => {
    connect('injected');
  }, [connect]);

  const checkLocalUserAccount = useCallback(async () => {
    if (!localStorage.getItem('account')) {
      setUserAccount(null);
    }
  }, []);

  const fetchConnection = useCallback(async () => {
    if (status === 'disconnected') {
      setUserAccount(null);
      localStorage.removeItem('account');
    }
  }, [status, setUserAccount]);

  useEffect(() => {
    checkLocalUserAccount();
    const localAccount: string | null =
      (account ? account.toString() : false) || localStorage.getItem(ACCOUNT_ADDRESS_STORAGE_KEY);
    if (localAccount) {
      localStorage.setItem(ACCOUNT_ADDRESS_STORAGE_KEY, localAccount);
      setUserAccount(localAccount);
    }
  }, [account, userAccount]);

  useEffect(() => {
    const checkConnection = setTimeout(() => {
      fetchConnection();
    }, 2000);
    return () => {
      clearTimeout(checkConnection);
    };
  }, [status, fetchConnection]);

  useEffect(() => {
    const localAccount = localStorage.getItem(ACCOUNT_ADDRESS_STORAGE_KEY);
    const walletProvider = localStorage.getItem(WALLET_PROVIDER_STORAGE_KEY);
    if (!account && localAccount) {
      setUserAccount(localAccount);
      if (localAccount && (walletProvider === 'metamask' || walletProvider === 'injected')) {
        handleConnectMetamask();
      }
    }
  }, []);

  return (
    <StyledWalletButton>
      {!userAccount ? (
        <Button onClick={handleConnectMetamask} size="sm" text="Unlock Wallet" />
      ) : (
        <Button
          onClick={handleSignOut}
          size="sm"
          text={`${userAccount.substring(0, 6)} - Disconnect`}
          variant="secondary"
        />
      )}
    </StyledWalletButton>
  );
};

const StyledWalletButton = styled.div``;
