import React, { useCallback, useState } from 'react';
import { useWeb3React, Web3ReactProvider } from '@web3-react/core';
import { injected, WalletConnectorType } from 'utils/connectors';
import { WalletContext, WalletStatus } from './wallet-context';

const WalletProviderInner: React.FC = ({ children }) => {
  const [connector, setConnector] = useState<WalletConnectorType | null>(null);
  const [status, setStatus] = useState<WalletStatus>('disconnected');
  const { account, activate, active, deactivate } = useWeb3React();

  const reset = useCallback(() => {
    if (active) deactivate();

    setConnector(null);
    setStatus('disconnected');
  }, [account, active, connector, status]);

  const connect = useCallback(
    async (walletType) => {
      try {
        reset();
        setConnector(walletType);
        setStatus('loading');

        if (walletType === 'injected') {
          await activate(injected, undefined, true);
          setStatus('connected');
        } // TODO: other wallet types (walletconnect, etc)
      } catch (err) {
        setStatus('disconnected');
        setConnector(null);
        console.log(err);
        if (err.message) {
          window.alert(err.message);
        }
      }
    },
    [account, connector],
  );

  const { library: ethereum } = useWeb3React();

  return (
    <WalletContext.Provider
      value={{
        account,
        connector,
        ethereum,
        status,
        connect,
        reset,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

interface WalletProviderProps {
  children: React.ReactNode;
}
export const WalletProvider = (props: WalletProviderProps): JSX.Element => {
  return (
    <Web3ReactProvider getLibrary={(ethereum) => ethereum}>
      <WalletProviderInner {...props} />
    </Web3ReactProvider>
  );
};
