import React from 'react';
import { Container } from 'react-neu';
import styled from 'styled-components';
import { WalletButton } from 'components/wallet-button';
import { Logo } from './logo';
import { DarkModeSwitch } from './dark-mode-switch';

export const TopBar: React.FC = () => {
  return (
    <Container size="lg">
      <StyledTopBarInner>
        <StyledLogoWrapper>
          <Logo />
        </StyledLogoWrapper>
        <StyledRightMenu>
          <StyledTopBarDarkModeSwitch>
            <DarkModeSwitch />
          </StyledTopBarDarkModeSwitch>
          <StyledAccountButtonWrapper>
            <WalletButton />
          </StyledAccountButtonWrapper>
        </StyledRightMenu>
      </StyledTopBarInner>
    </Container>
  );
};

const StyledLogoWrapper = styled.div`
  width: 170px;
  @media (max-width: 400px) {
    width: auto;
  }
`;

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;
`;

const StyledTopBarDarkModeSwitch = styled.div`
  @media (max-width: 1130px) {
    display: none;
  }
`;

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-left: 30px;
  @media (max-width: 400px) {
    justify-content: center;
    width: auto;
  }
`;

const StyledRightMenu = styled.div`
  align-items: center;
  display: flex;
  height: 72px;
  justify-content: space-between;
`;
