import React, { useMemo } from 'react';
import { createTheme, ThemeProvider } from 'react-neu';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { WalletProvider } from 'contexts/wallet';
import { DARK_MODE_STORAGE_KEY } from 'constants/storage';
import { useLocalStorage } from 'hooks/use-local-storage';
import { TopBar } from 'components/top-bar';
import { Home } from 'views/home';
import { Bancor } from 'views/bancor';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    ethereum: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
  }
}

export const App: React.FC = () => {
  return (
    <Router>
      <Providers>
        <TopBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/bancor">
            <Bancor />
          </Route>
        </Switch>
      </Providers>
    </Router>
  );
};

const Providers: React.FC = ({ children }) => {
  const [darkModeSetting] = useLocalStorage(DARK_MODE_STORAGE_KEY, false);

  const { dark: darkTheme, light: lightTheme } = useMemo(() => {
    return createTheme({
      // rgb(244 255 244))
      baseColor: { h: 110, s: 75, l: 48 },
      baseColorDark: { h: 110, s: 55, l: 48 },
      // s and l are unused?
      baseGreyColorDark: { h: 220, s: 0, l: 0 },
      borderRadius: 28,
    });
  }, []);

  return (
    <ThemeProvider darkModeEnabled={darkModeSetting} darkTheme={darkTheme} lightTheme={lightTheme}>
      <WalletProvider>{children}</WalletProvider>
    </ThemeProvider>
  );
};
